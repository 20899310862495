.showPluviButton {
  width: 184px;
  height: 40px;
  border-radius: 5px;
  background: #032677;
  color: white;
  text-align: center;
  transition: 0.3s;
  margin-top: 5px;
  font-size: 12px;
}

.selectedPluviButton {
  background-color: #bee6ff;
  color: #032677;
  text-align: center;
  transition: 0.3s;
  margin-top: 5px;
  width: 184px;
  height: 40px;
  border-radius: 5px;
  font-size: 12px;
}
