.triangleMarker {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 25px solid red;
}

.circleBigMarker {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: blue;
}

.circleMiniMarker {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid rgb(146, 146, 146); /* Adicionando uma borda preta */
}
