.InmetReferenceButton {
  display: grid;
  align-content: center;
  cursor: pointer;
  position: absolute;
  top: 3%;
  left: 94.7%;
  z-index: 10;
  padding: 2px;
  border-radius: 5px;
  background-color: #032677;
  color: white;
  font-size: 10px;
  transition: 0.3s;
  animation: smoothAppear 1s 1;
  opacity: 0.4;
  transition: 0.2s;
}
.InmetReferenceButton svg {
  font-size: 20px;
}

.InmetReferenceButton:hover {
  border-radius: 0px;
  opacity: 1;
}
.imgInmet {
  width: 34px;
  border-radius: 5px;
}

@media screen and (max-width: 1520px) {
  .InmetReferenceButton {
    left: 94%;
  }
}

@media screen and (max-width: 1536px) {
  .InmetReferenceButton {
    left: 94%;
  }
}

@media screen and (max-width: 1370px) {
  .InmetReferenceButton {
    left: 91.7%;
  }
}
@media screen and (max-width: 1280px) {
  .InmetReferenceButton {
    left: 91.3%;
  }
}
