.LoadingScreenDiv {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
}

.LoadingScreenDiv h1 {
  position: absolute;
  top: 53%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  color: white;
  font-size: 20px;
  animation: blink 1s infinite;
}

.CloudAnimation {
  width: 50px;
  height: 40px;
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/CloudLoadingIcon.png");
  background-repeat: no-repeat;
  background-size: contain;
  animation: blink 1s infinite;
}

@keyframes blink {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
