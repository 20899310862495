.showHidroeletricsButton {
  width: 184px;
  height: 40px;
  border-radius: 5px;
  background: #032677;
  color: white;
  text-align: center;
  transition: 0.3s;
}

.showHidroeletricsButton:hover {
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0;
}
.descriptionText {
  color: #8c8c8c;
}
.selectedData {
  color: #032677;
  max-width: 184px;
  font-family: "Montserrat", sans-serif;
}

.selectedData option {
  font-size: 12px;
}

.usinaSafiraCheckbox {
  display: grid;
  justify-content: center;
  grid-template-columns: 0.1fr 1fr;
  font-family: "Montserrat", sans-serif;
  gap: 8px;
  margin-top: 6px;
}

.usinaSafiraCheckbox label {
  font-family: "Montserrat", sans-serif;
}
