textarea:focus,
input:focus {
  outline: none !important;
}

.firstDiv {
  position: absolute;
  z-index: 4;
  top: 26px;
  left: 200px;
  opacity: 0.4;
  transition: 0.2s;
}

/* has - pegue todas as divs que tem um input dentro */
.firstDiv:has(input:focus) {
  opacity: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.firstDiv:hover {
  opacity: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.firstDiv input {
  background-color: #f1f1f1;
}

.dataResult {
  position: absolute;
  background: #f1f1f1;
  top: 35px;
  left: 35px;
  border-radius: 5px;
  max-height: 387px;
  overflow: hidden;
  overflow-y: auto;
  width: 338px;
  z-index: -1;
}
.dataResult:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.dataItem {
  display: grid;
  padding: 6px 32px;
  gap: 16px;
  font-family: monospace;
  font-size: 12px;
  font-weight: bold;
  cursor: pointer;
  transition: 0.2s;
  width: 390px;
}

.searchBar {
  position: absolute;
  border-radius: 5px;
  font-family: monospace;
  text-align: left;
  width: 300px;
  height: 35px;
  font-size: 16px;
  padding-left: 40px;
  background-repeat: no-repeat;
  background-position: 2% 50%;
  background-size: 14px;
  margin-left: 35px;
  border: none;
  transition: 0.2;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.searchBar:hover {
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.searchSpan {
  position: absolute;
  color: #032677;
  border-radius: 0px 5px 5px 0px;
  margin-left: 42px;
  width: 22px;
  height: 35px;
  transition: 0.2s;
}
.searchSpan span {
  display: grid;
  padding-top: 7px;
}

.iconSearch {
  width: 100%;
  height: 100%;
}

.dataItem:hover {
  background-color: #032677;
  color: #bee6ff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.clearButton {
  position: absolute;
  border-radius: 0px 5px 5px 0px;
  background-color: #f1f1f1;
  margin-left: 350px;
  width: 24px;
  height: 35px;
  transition: 0.2s;
}

.clearButton svg {
  display: grid;
  color: #bee6ff;
}

.clearButton:hover {
  background-color: #bee6ff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.iconClose {
  color: #032677;
}

@media screen and (max-width: 1920px) {
  .dataResult {
    max-height: 387px;
  }
}
