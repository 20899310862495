.redirectDiv {
  display: grid;
  place-items: center; /* Centraliza horizontalmente e verticalmente */
  justify-content: center;
  width: 60px;
  height: 40px;
  background-color: #032677;
  color: white;
  position: absolute;
  z-index: 2;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.5s ease-in-out; /* Adiciona a transição para a propriedade transform */
}
.redirectDiv a {
  width: 100%;
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/icones/favicon.svg");
  background-repeat: no-repeat;
  background-size: cover;
}

.active {
  transform: translate(-50%); /* Define a transformação para mostrar a div */
}

.inactive {
  transform: translate(
    -50%,
    -100%
  ); /* Define a transformação para ocultar a div */
}

.buttonDiv {
  left: 50%;
  transform: translate(-50%);
  display: grid;
  justify-content: center;
  width: 35px;
  height: 18px;
  position: absolute;
  color: white;
  background-color: #011b57;
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.buttonDiv button {
  color: white;
  margin: 0;
  padding: 0;
}

.buttonDiv:hover {
  color: #011b57;
  background-color: #bee6ff;
  margin: 0;
  padding: 0;
  transition: 0.2s;
}

.buttonDivActive {
  left: 50%;
  transform: translate(-50%);
  display: grid;
  justify-content: center;
  top: 40px;
  width: 35px;
  height: 18px;
  position: absolute;
  color: white;
  background-color: #011b57;
  z-index: 1;
  border-radius: 0px 0px 5px 5px;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}
.buttonDivActive:hover {
  background-color: #bee6ff;
}

.buttonDivActive button {
  color: white;
  margin: 0;
  padding: 0;
}

.buttonDivActive button:hover {
  color: #011b57;
  margin: 0;
  padding: 0;
  transition: 0.2s;
}
