.HydroeletricsTimeRange p,
h1,
h2,
h3 {
  margin: 0px;
  padding: 0px;
}

@keyframes smoothAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

.HydroeletricsTimeRange {
  position: absolute;
  background-color: white;
  display: grid;
  width: 94%;
  border-radius: 5px;
  top: 83.8%;
  left: 20px;
  border-bottom: 25px solid white;
  border-left: 40px solid white;
  border-right: 40px solid white;
  border-top: 25px solid white;
  animation: smoothAppear 0.5s 1;
  opacity: 0.4;
  transition: 0.2s;
}

.HydroeletricsTimeRange:hover {
  opacity: 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
  animation: smoothAppear 0.5s 1;
}

@media screen and (max-width: 1520px) {
  .HydroeletricsTimeRange {
    top: 78%;
    left: 20px;
    width: 92%;
  }
}

@media screen and (max-width: 1536px) {
  .HydroeletricsTimeRange {
    top: 78%;
    left: 20px;
    width: 92%;
  }
}

@media screen and (max-width: 1370px) {
  .HydroeletricsTimeRange {
    position: absolute;
    background-color: white;
    display: grid;
    width: 91%;
    border-radius: 5px;
    top: 76%;
    left: 20px;
    border-bottom: 25px solid white;
    border-left: 40px solid white;
    border-right: 40px solid white;
    border-top: 25px solid white;
  }
}

@media screen and (max-width: 1810px) {
  .HydroeletricsTimeRange {
    position: absolute;
    background-color: white;
    display: grid;
    width: 91%;
    border-radius: 5px;
    top: 76%;
    left: 20px;
    border-bottom: 25px solid white;
    border-left: 40px solid white;
    border-right: 40px solid white;
    border-top: 25px solid white;
  }
}
