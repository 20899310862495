@keyframes smoothAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navBar {
  display: grid;
  position: absolute;
  height: 60%;
  top: 3%;
  margin-left: 20px;
  border-radius: 5px;
  background-color: #f1f1f1;
  opacity: 0.4;
  transition: 0.2s;
}

.navBar:hover {
  opacity: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.menuButton {
  display: grid;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  z-index: 10;
  padding: 18px;
  border-radius: 5px;
  background-color: #032677;
  color: white;
  transition: 0.3s;
}
.menuButton:hover {
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0px;
}
.menuButton p {
  margin: 0px;
  padding: 0px;
  position: absolute;
  top: 12px;
  left: 4px;
}

.menuButtonActive {
  width: 200px;
  display: grid;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  font-size: 20px;
  z-index: 10;
  padding: 18px;

  background-color: #bee6ff;
  color: #032677;
  transition: 0.3s;
}

.menuNav {
  display: none;
  top: 0;
  background: #f1f1f1;
  left: 0;
  bottom: 0;
  height: 120vh;
  width: 30px;
  z-index: 9;
  border-radius: 5px;
  border: 1px solid black;
  animation: smoothAppear 1s 1;
}

.menuNav a {
  display: grid;
  color: black;
  text-decoration: none;
  justify-content: center;
}

.navBar .showMenu {
  animation: smoothAppear 0.5s 1;
  width: 200px;
}

.iconContainer {
  position: inherit;
  padding: 8px;
}

.menuNav li:first-child {
  margin-top: 7rem;
}

.navLink {
  list-style-type: none;
}

.selectModels {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  transition: 0.3s;
}

.selectModels h3 {
  grid-column: 1 / span 3;
}

.selectModels button {
  width: 50px;
  font-size: 10px;
  margin: 4px;
  border-radius: 5px;
  height: 36px;
}

.selectModels h3 {
  padding-left: 8px;
}

.selectHidroeletrics {
  display: grid;
  padding-left: 8px;
  padding-top: 50px;
  padding-bottom: 20px;
}
.selectHidroeletrics h3 {
  padding-bottom: 12px;
}

.selectStations {
  display: grid;
  padding-left: 8px;
}

.selectStations h3 {
  padding-bottom: 12px;
}

.selectChilePlants {
  display: grid;
  padding-left: 8px;
}

.selectChilePlants h3 {
  padding-bottom: 12px;
}

.btnGfs {
  background-color: #032677;
  color: white;
  transition: 0.3s;
}
.btnGfs:hover {
  border-radius: 0;
  background-color: #bee6ff;
  color: #032677;
}

.btnGefs {
  background-color: #032677;
  color: white;
  transition: 0.3s;
}
.btnGefs:hover {
  background-color: grey;
  color: white;
  transition: 0.3s;
}
.btnEcmwf {
  background-color: #032677;
  color: white;
  transition: 0.3s;
}
.btnEcmwf:hover {
  border-radius: 0;
  background-color: #bee6ff;
  color: #032677;
}

.selectPluvio {
  padding-top: 20px;
  display: grid;
  padding-left: 8px;
}

.selectShapesDiv {
  display: grid;
  padding-left: 8px;
  padding-top: 10px;
}

@media screen and (max-width: 1520px) {
  .navBar {
    height: 70%;
  }
}
@media screen and (max-width: 1536px) {
  .navBar {
    height: 70%;
  }
}
@media screen and (max-width: 1370px) {
  .navBar {
    height: 70%;
  }
}

@media screen and (max-width: 1810px) {
  .navBar {
    height: 70%;
  }
}

@media screen and (max-height: 780px) {
  .navBar {
    height: 72%;
  }

  .navBar .showMenu {
    animation: smoothAppear 0.5s 1;
    width: 210px;
    overflow: auto;
  }
  .navBar .showMenu::-webkit-scrollbar-thumb {
    background: #032677;
  }
}
