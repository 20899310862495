.changeStyleButton {
  padding: 18px;
  border-radius: 5px;
  background-color: #032677;
  color: white;
  font-size: 10px;
  background-size: cover;
  background-position: center;
  opacity: 0.4;
  transition: 0.2s;
}
.changeStyleButton:hover {
  opacity: 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
  border-radius: 0;
}

.changeStyleButton:nth-child(1) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/satelliteStreetsv12.png");
}
.changeStyleButton:nth-child(2) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/outdoorsv12.png");
}
.changeStyleButton:nth-child(3) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/lightv12.png");
}
.changeStyleButton:nth-child(4) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/darkv11.png");
}
.changeStyleButton:nth-child(5) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/navday1.png");
}
.changeStyleButton:nth-child(6) {
  background-image: url("https://prisma.safiraenergia.com.br:3333/images/mapstyles/navnight1.png");
}

.buttonContainer {
  position: absolute;
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 19px;
  gap: 8px;
  left: 95.97%;
  top: 126px;
}

@media screen and (max-width: 1520px) {
  .buttonContainer {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 19px;
    gap: 8px;
    left: 95.7%;
    top: 118px;
  }
}

@media screen and (max-width: 1536px) {
  .buttonContainer {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 19px;
    gap: 8px;
    left: 95.7%;
    top: 120px;
  }
}

@media screen and (max-width: 1370px) {
  .buttonContainer {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 19px;
    gap: 8px;
    left: 93.6%;
    top: 118px;
  }
}

@media screen and (max-width: 1280px) {
  .buttonContainer {
    position: absolute;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    padding: 19px;
    gap: 8px;
    left: 93.5%;
    top: 118px;
  }
}
