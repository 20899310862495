.FullScreenButton {
  display: grid;
  align-content: center;
  cursor: pointer;
  position: absolute;
  top: 3%;
  left: 97%;
  z-index: 10;
  padding: 18px;
  border-radius: 5px;
  background-color: #032677;
  color: white;
  font-size: 10px;
  transition: 0.3s;
  animation: smoothAppear 1s 1;
  opacity: 0.4;
  transition: 0.2s;
}
.FullScreenButton svg {
  font-size: 20px;
}

.FullScreenButton:hover {
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0px;
  opacity: 1;
}

.FullScreenButton p {
  font-family: "Montserrat", sans-serif;
}

@media screen and (max-width: 1536px) {
  .FullScreenButton {
    left: 97%;
  }
}
@media screen and (max-width: 1370px) {
  .FullScreenButton {
    left: 95%;
  }
}
