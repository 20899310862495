.showChilePlantsButton {
  height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 184px;
  display: grid;
  background: #032677;
  color: white;
  transition: 0.3s;
  font-size: 13px;
  margin-top: 30px;
}

.showChilePlantsButtonActive {
  transition: 0.3s;
  font-size: 13px;
  margin-top: 30px;
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0;
  height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 184px;
  display: grid;
}

.showChilePlantsButton:hover {
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0;
}

@media screen and (max-height: 572px) {
  .showChilePlantsButton {
    margin-top: 5px;
  }
  .showChilePlantsButtonActive {
    margin-top: 5px;
  }
}
