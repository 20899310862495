.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 8;
  color: #031e4c;
}

.container {
  display: grid;
  position: absolute;
  top: 50%;
  left: 53%;
  transform: translate(-55%, -50%);
  background-color: #f1f1f1;
  width: 60%;
  height: 40%;
  border-radius: 5px;
  padding: 20px;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
}

.descriptionGrid {
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
  align-items: center;
}

.descriptionGrid h1 {
  margin: 0;
  color: #032677;
  align-self: center;
}

.chartContainer {
  display: grid;
  width: 100%;
  height: 100%;
  gap: 10px;
}

.chartContainer h1 {
  color: #032677;
}
.inputContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.clearButton {
  color: #f1f1f1;
  background-color: #032677;
  width: 35px;
  height: 35px;
  transition: 0.2s;
  border-radius: 5px;
  z-index: 5;
  font-size: 15px;
}

.clearButton:hover {
  color: #032677;
  background-color: #bee6ff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
  border-radius: 0px;
}

.dateSelector {
  outline: none;
  border: 2px solid rgba(0, 0, 0, 0.1);
  color: #031e4c;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 5px 10px 10px;
  font-size: medium;
  flex-grow: 1;
}

.squareContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  justify-items: center;
  padding-left: 10%;
  padding-right: 10%;
}

.squareWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.squareWrapper p {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #031e4c;
  font-weight: bold;
  font-size: 12px;
}

.square {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 5px;
  justify-items: center;
}
.square img {
  margin-top: 10px;
  max-width: 50%;
  object-fit: contain;
}

@media screen and (max-width: 1370px) {
  .container {
    width: 65%;
    left: 52%;
    top: 50%;
    height: 65%;
  }
  .chartContainer {
    width: 100%;
  }
  .clearButton {
    top: 18%;
    left: 82.8%;
  }
  .squareContainer {
    padding-left: 0%;
    padding-right: 0%;
  }
}
