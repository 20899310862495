.timeRange {
  position: absolute;
  background-color: #ffffff;
  display: grid;
  height: 80px;
  width: 94%;
  border-radius: 5px;
  top: 83.8%;
  left: 20px;
  border-bottom: 24px solid white;
  border-left: 40px solid white;
  border-right: 40px solid white;
  border-top: 22px solid white;
  animation: smoothAppear 0.5s 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
  opacity: 0.4;
  transition: 0.2s;
}

.timeRange p {
  font-size: 10px;
}

.timeRange:hover {
  opacity: 1;
}

.TimeIntervalButtons {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  top: 86%;
  left: 82.2%;
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 20%;
  gap: 10px;
}
.TimeIntervalButtons p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px;
}

.TimeIntervalButtons button {
  border-radius: 5px;
  background: #032677;
  color: white;
  transition: 0.3s;
  padding: 15px;
  height: 100%;
  width: 50px;
}
.TimeIntervalButtons button:hover {
  background-color: #bee6ff;
  color: #032677;
}
.timeIntervalSelectedButton {
  background-color: #bee6ff;
  color: #032677;
}

@media screen and (max-width: 1370px) {
  .timeRange {
    top: 76.5%;
    width: 91.5%;
  }
  .TimeIntervalButtons {
    top: 80.5%;
    left: 84%;
  }
}

@media screen and (max-width: 1536px) {
  .timeRange {
    top: 77.6%;
    width: 91.5%;
  }
  .TimeIntervalButtons {
    top: 80.5%;
    left: 84%;
  }
}

@media screen and (max-width: 1810px) {
  .timeRange {
    top: 77.6%;
    width: 91.5%;
  }
  .TimeIntervalButtons {
    top: 80.5%;
    left: 84%;
  }
}
