* {
  padding: 0;
  margin: 0;
}

html {
  width: 100%;
  height: 100%;
}

body button {
  font-family: "Montserrat", sans-serif;
  margin: 0px;
}

h1,
h2,
h3,
h4,
p {
  font-family: "Montserrat", sans-serif;
}

/*css referenciando os botões de zoom in e out*/
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-in
  > span {
  filter: brightness(100);
  border-radius: 5px;
}
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-in
  > span:hover {
  filter: opacity(1) drop-shadow(0 0 #032677);
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-out
  > span {
  filter: brightness(100);
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-out
  > span:hover {
  filter: opacity(1) drop-shadow(0 0 #032677);
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div {
  display: grid;
  width: 36px;
  height: 70px;
  opacity: 0.4;
  transition: 0.3s;
  animation: smoothAppear 1s 1;
  margin-top: 70px;
  margin-right: 22px;
}
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div:hover {
  width: 36px;
  opacity: 1;
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-in {
  height: 100%;
  width: 100%;
}
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-out {
  width: 100%;
  height: 100%;
}

#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div {
  display: grid;
  margin: 0;
  padding: 0;
  border: 0;
  margin-top: 70px;
  margin-right: 22px;
}
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-in:hover {
  background-color: #bee6ff;
  border-radius: 0px;
}
#root
  > div
  > div
  > div.mapboxgl-control-container
  > div.mapboxgl-ctrl-top-right
  > div
  > button.mapboxgl-ctrl-zoom-out:hover {
  background-color: #bee6ff;
  border-radius: 0px;
  opacity: 1;
}

@media screen and (max-width: 1570px) {
  #root
    > div
    > div
    > div.mapboxgl-control-container
    > div.mapboxgl-ctrl-top-right
    > div {
    display: grid;
    margin: 0;
    padding: 0;
    border: 0;
    margin-top: 64px;
    margin-right: 11px;
  }
}
@media screen and (max-width: 1520px) {
  #root
    > div
    > div
    > div.mapboxgl-control-container
    > div.mapboxgl-ctrl-top-right
    > div {
    display: grid;
    margin: 0;
    padding: 0;
    border: 0;
    margin-top: 64px;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1370px) {
  #root
    > div
    > div
    > div.mapboxgl-control-container
    > div.mapboxgl-ctrl-top-right
    > div {
    display: grid;
    margin: 0;
    padding: 0;
    border: 0;
    margin-top: 64px;
    margin-right: 32px;
  }
}
@media screen and (max-width: 1280px) {
  #root
    > div
    > div
    > div.mapboxgl-control-container
    > div.mapboxgl-ctrl-top-right
    > div {
    display: grid;
    margin: 0;
    padding: 0;
    border: 0;
    margin-top: 64px;
    margin-right: 28px;
  }
}

*::-webkit-scrollbar {
  width: 10px;
  background-color: rgb(153, 153, 153);
  border-radius: 3px;
}
