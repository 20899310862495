@keyframes smoothAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

button {
  width: 20px;
  height: 20px;
  display: grid;
  justify-content: center;
  cursor: pointer;
  background: transparent;
  border: none;
  padding: 11px;
}

.naturalFlow {
  border-radius: 40px;
  background-color: #bee6ff;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.naturalFlow p {
  animation: smoothAppear 1s 0.3;
  font-size: 10px;
  padding-top: 9px;
}

.naturalFlowFirst {
  color: white;
  border-radius: 40px;
  background-color: red;
  height: 30px;
  width: 30px;
  font-weight: bold;
  border: 1px solid white;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.effluentFlow {
  border-radius: 40px;
  background-color: #ffd766;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.effluentFlow p {
  animation: smoothAppear 1s 0.3;
  font-size: 10px;
  padding-top: 9px;
}

.storedVolume {
  border-radius: 40px;
  background-color: #ff9852;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.storedVolume p {
  animation: smoothAppear 1s 0.3;
  font-size: 10px;
  padding-top: 9px;
  padding-top: 9px;
}

.level {
  border-radius: 40px;
  background-color: #a1e44d;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
}

.level p {
  margin-bottom: 15px;
  padding: 0px;
  font-size: 10px;
  animation: smoothAppear 1s 0.3;
  padding-top: 9px;
}

.stationImage {
  animation: smoothAppear 1s 0.3;
}

.turbochargedFlow {
  border-radius: 40px;
  background-color: #cd7ef5;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.turbochargedFlow p {
  font-size: 10px;
  padding-top: 9px;
}

.spilledFlow {
  border-radius: 40px;
  background-color: #cd7ef5;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 0.3;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.spilledFlow p {
  font-size: 10px;
  padding-top: 9px;
}
