.divStationsData {
  display: grid;
  margin-right: 6px;
}

.showStationsButton {
  height: 40px;
  border-radius: 5px;
  text-align: center;
  width: 184px;
  display: grid;
  background: #032677;
  color: white;
  transition: 0.3s;
  font-size: 13px;
}

.showStationsButton:hover {
  background-color: #bee6ff;
  color: #032677;
  border-radius: 0;
}

.descriptionText {
  color: #8c8c8c;
}
.selectedData {
  color: #032677;
  max-width: 184px;
  font-family: "Montserrat", sans-serif;
}
