.descriptionChile {
  position: absolute;
  z-index: 0;
  width: 10%;
  height: 20;
  display: grid;
  justify-content: center;
  gap: 10px;
  padding: 10px;
  grid-template-columns: 1fr 0.3fr;
  left: 85.6%;
  top: 7.4%;
  background: #f1f1f1;
  border-radius: 5px;
  row-gap: 10px;
}

.simbolsChile {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: center;
}

.redTriangle {
  width: 0;
  height: 0;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  border-top: 20px solid red;
}

/* Estilize a bola azul */
.blueBall {
  width: 20px;
  height: 20px;
  background-color: blue;
  border-radius: 50%;
  margin-left: 5px;
}

/* Estilize a bola branca com bordas cinzas */
.whiteBall {
  width: 15px;
  height: 15px;
  background-color: white;
  border: 2px solid #e6e6e6;
  border-radius: 50%;
  margin-left: 6px;
}

@media screen and (max-width: 1520px) {
  .descriptionChile {
    left: 82.2%;
    top: 8.7%;
    width: 13%;
  }
}
@media screen and (max-width: 1536px) {
  .descriptionChile {
    left: 82.2%;
    top: 8.7%;
    width: 13%;
  }
}

@media screen and (max-width: 1370px) {
  .descriptionChile {
    left: 78%;
    top: 8.7%;
    width: 15%;
  }
}
@media screen and (max-width: 1280px) {
  .descriptionChile {
    left: 78%;
    top: 8.7%;
    width: 15%;
  }
}
@media screen and (max-height: 572px) {
  .descriptionChile {
    left: 77.8%;
    top: 11.3%;
    width: 15%;
  }
}
