@keyframes smoothAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.rain {
  border-radius: 40px;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 1;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.rain p {
  font-size: 10px;
  padding-top: 9px;
}

.zeroRain {
  border-radius: 40px;
  height: 8px;
  width: 8px;
  font-weight: bold;
  opacity: 0.3;
  border: 1px solid black;
  animation: smoothAppear 3s 1;
}

.nullRain {
  border-radius: 40px;
  height: 8px;
  width: 8px;
  font-weight: bold;
  opacity: 0.2;
  border: 3px solid red;
  animation: smoothAppear 3s 1;
}

.tmpMin {
  border-radius: 40px;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 1;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.tmpMin p {
  font-size: 10px;
  padding-top: 9px;
}

.tmpMax {
  border-radius: 40px;
  height: 30px;
  width: 30px;
  font-weight: bold;
  animation: smoothAppear 1s 1;
  box-shadow: 2px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.tmpMax p {
  font-size: 10px;
  padding-top: 9px;
}

.stationImage {
  animation: smoothAppear 1s 1;
}
