.ColoursIndicatorDiv {
  display: grid;
  grid-template-columns: 30px 10px;
  position: absolute;
  left: 95%;
  top: 42%;
  z-index: 10;
  background-color: #ffffff;
  border-radius: 5px;
  z-index: 7;
}

.MilimetersValue {
  display: grid;
  justify-content: center;
  font-weight: bold;
  height: 20px;
  width: 55px;
  border-radius: 5px;
}

.indicatorPrecipitation {
  margin: 0;
  padding: 0;
  position: relative;
  top: 62%;
  transform: rotate(-90deg);
  white-space: nowrap;
}

.rainDisclaimer {
  display: grid;
  grid-template-rows: 0.2fr 1fr;
  justify-content: left;
  top: 58%;
  left: 81%;
  width: 230px;
  height: 150px;
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: left;
  padding: 10px;
  opacity: 0.5;
  transition: 0.2s;
  gap: 6px;
}
.rainDisclaimer:hover {
  opacity: 1;
}

.attemptHeader {
  display: grid;
  justify-content: left;
  align-items: center;
  grid-template-columns: auto auto 1fr 0.4fr;
  background-color: #f1f1f1;
  border-radius: 5px;
  gap: 8px;
}

.disclaimerCloseButton:hover {
  background-color: #bee6ff;
  border-radius: 0px;
  transition: 0.2s;
}

@media screen and (max-width: 1920px) {
  .rainDisclaimer {
    top: 58%;
    left: 81.5%;
  }
}

@media screen and (max-width: 1810px) {
  .ColoursIndicatorDiv {
    left: 92%;
    top: 10.5%;
  }
  .MilimetersValue {
    height: 20.8px;
    width: 55px;
  }
  .rainDisclaimer {
    top: 56%;
    left: 81.5%;
  }
}
@media screen and (max-width: 1536px) {
  .ColoursIndicatorDiv {
    left: 91%;
    top: 9%;
  }
  .MilimetersValue {
    height: 20.8px;
    width: 55px;
  }
  .rainDisclaimer {
    top: 9.2%;
    left: 74%;
  }
}

@media screen and (max-width: 1520px) {
  .ColoursIndicatorDiv {
    left: 91%;
    top: 9.2%;
  }
  .MilimetersValue {
    height: 20.8px;
    width: 55px;
  }
  .rainDisclaimer {
    top: 9.2%;
    left: 74%;
  }
}

@media screen and (max-width: 1370px) {
  .ColoursIndicatorDiv {
    left: 88.3%;
    top: 9.2%;
  }
  .MilimetersValue {
    height: 20.8px;
    width: 55px;
  }
  .rainDisclaimer {
    top: 9.2%;
    left: 69%;
  }
}
@media screen and (max-width: 1280px) {
  .ColoursIndicatorDiv {
    left: 87.5%;
    top: 10.5%;
  }
  .MilimetersValue {
    height: 20.8px;
    width: 55px;
  }
  .rainDisclaimer {
    top: 10.5%;
    left: 67%;
  }
}
