.StationTimeRange p,
h1,
h2,
h3 {
  margin: 0px;
  padding: 0px;
}

@keyframes smoothAppear {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.4;
  }
}

.IntervalTime {
  position: absolute;
  background-color: white;
  margin: 0px;
  border: 0px;
  display: grid;
  justify-content: end;
  top: 80%;
  left: 92.1%;
  padding: 12px;
  border-radius: 5px;
  max-height: 20px;
}

.StationTimeRange {
  position: absolute;
  background-color: white;
  display: grid;
  width: 94%;
  border-radius: 5px;
  top: 83.8%;
  left: 20px;
  border-bottom: 25px solid white;
  border-left: 40px solid white;
  border-right: 40px solid white;
  border-top: 25px solid white;
  animation: smoothAppear 0.5s 1;
  opacity: 0.4;
  transition: 0.2s;
}

.StationTimeRange:hover {
  opacity: 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
}

.StationTimeRange p {
  margin: 0;
  padding: 0;
}

.StationTimeRange h3 {
  margin: 0;
  padding: 0;
}

.TimeIntervalButtons {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  top: 86%;
  left: 85.5%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 10%;
  gap: 5px;
}

.TimeIntervalButtons p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px;
}

.TimeIntervalButtons button {
  border-radius: 5px;
  background: #032677;
  color: white;
  transition: 0.3s;
  padding: 15px;
  height: 100%;
  width: 50px;
}

.TimeIntervalButtons button:hover {
  background-color: #bee6ff;
  color: #032677;
}

.timeIntervalSelectedButton {
  background-color: #bee6ff;
  color: #032677;
}

.TempMinDiv {
  position: absolute;
  background-color: white;
  display: grid;
  width: 12%;
  border-radius: 5px;
  top: 83.5%;
  left: 20px;
  border-bottom: 19px solid white;
  border-left: 40px solid white;
  border-right: 40px solid white;
  border-top: 25px solid white;
  animation: smoothAppear 0.5s 1;
  box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.2);
}

.tmpMinButtons {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  grid-template-columns: 1fr 1fr;
  left: 91.9%;
  display: grid;
  width: 6%;
  top: 86%;
}
.tmpMinButtons p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px;
}

.tmpMinButtons button:hover {
  background-color: #bee6ff;
  color: #032677;
}

.tmpMinButtons button {
  border-radius: 5px;
  background: #032677;
  color: white;
  transition: 0.3s;
  padding: 15px;
  height: 100%;
  width: 50px;
}

.TempMinDivSelected {
  position: absolute;
  background-color: white;
  display: grid;
  width: 18%;
  border-radius: 5px;
  top: 83.8%;
  left: 20px;
  border-bottom: 28px solid white;
  border-left: 40px solid white;
  border-right: 40px solid white;
  border-top: 25px solid white;
  opacity: 0.4;
}

.TempMinDivSelected:hover {
  opacity: 1;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.2);
}

.rainMinSelected {
  white-space: nowrap;
  z-index: 1;
  position: absolute;
  grid-template-columns: 1fr 1fr;
  left: 16%;
  display: grid;
  width: 6%;
  top: 88%;
}
.rainMinSelected p {
  font-weight: bold;
  font-size: 12px;
  margin-top: 15px;
}

.rainMinSelected button {
  border-radius: 5px;
  background: #032677;
  color: white;
  transition: 0.3s;
  padding: 15px;
  height: 100%;
  width: 50px;
}

/* 1810px width styles (larger than 1536px) */
@media screen and (max-width: 1810px) {
  .StationTimeRange {
    top: 78%;
  }
  .TimeIntervalButtons {
    top: 81%;
    left: 85%;
  }
  .IntervalTime {
    top: 71%;
    left: 89%;
  }
  .TempMinDiv {
    top: 75%;
  }
  .tmpMinButtons {
    top: 80%;
    left: 88%;
    width: 8%;
  }
  .rainMinSelected {
    top: 84%;
    left: 13%;
    width: 8%;
  }
  .TempMinDivSelected {
    top: 76%;
    width: 15%;
    border-bottom: 38px solid white;
  }
}

@media screen and (max-width: 1536px) {
  .StationTimeRange {
    position: absolute;
    background-color: white;
    display: grid;
    width: 91%;
    border-radius: 5px;
    top: 78%;
    left: 20px;
    border-bottom: 25px solid white;
    border-left: 40px solid white;
    border-right: 40px solid white;
    border-top: 25px solid white;
  }
  .TimeIntervalButtons {
    top: 80%;
    left: 80.5%;
  }
  .StationTimeRange {
    top: 77%;
  }
  .TempMinDivSelected {
    width: 23%;
  }
  .rainMinSelected {
    left: 20%;
    top: 81%;
  }
}

@media screen and (max-width: 1520px) {
  .TimeIntervalButtons {
    white-space: nowrap;
    z-index: 1;
    position: absolute;
    top: 80%;
    left: 80.5%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 10%;
  }
  .TempMinDivSelected {
    width: 23%;
  }
  .rainMinSelected {
    left: 20%;
    top: 81%;
  }
}

@media screen and (max-width: 1370px) {
  .StationTimeRange {
    position: absolute;
    background-color: white;
    display: grid;
    width: 91%;
    border-radius: 5px;
    top: 76%;
    left: 20px;
    border-bottom: 25px solid white;
    border-left: 40px solid white;
    border-right: 40px solid white;
    border-top: 25px solid white;
  }
  .TimeIntervalButtons {
    top: 79%;
    left: 79.5%;
  }
  .IntervalTime {
    top: 70%;
    left: 89%;
  }
  .TempMinDiv {
    top: 75%;
  }
  .tmpMinButtons {
    top: 80%;
    left: 88%;
    width: 8%;
  }

  .TempMinDivSelected {
    width: 24%;
  }
  .rainMinSelected {
    left: 22%;
    top: 81%;
  }
}

@media screen and (max-width: 1320px) {
  .TimeIntervalButtons {
    left: 79%;
  }
  .TempMinDivSelected {
    width: 26%;
  }
  .rainMinSelected {
    left: 23%;
    top: 81%;
  }
}

@media screen and (max-width: 1280px) {
  .TimeIntervalButtons {
    top: 79%;
    left: 78.5%;
    gap: 20px;
  }
  .StationTimeRange {
    top: 76%;
  }
  .TempMinDivSelected {
    width: 26%;
  }
  .rainMinSelected {
    left: 24%;
    top: 81%;
  }
}

@media screen and (max-width: 1230px) {
  .TimeIntervalButtons {
    left: 78%;
  }
}

/* Smallest width: 768px */
@media screen and (height: 768px) {
  .IntervalTime {
    top: 72%;
    left: 89%;
  }
  .TimeIntervalButtons {
    top: 80%;
    left: 75%;
  }
}
